import React, { useEffect, useState } from "react";
import LeftSlider from "../../Inc/LeftSlider";
import Select from "react-select";
import { useWebsiteApi } from "../../context/websiteContext";
import { useParams } from "react-router-dom";
import { decryptId } from "../../utils/cryptoUtils";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { toast } from "react-toastify";
const UploadConveredLead = () => {
  const { pid, uid } = useParams();
  const { getAllHospitals, hospitals } = useWebsiteApi();
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const [selectedHospitals, setSelectedHospitals] = useState("");
  const [loading, setLoading] = useState(false);
  const [cost, setCost] = useState("");
  const [type, setType] = useState("");
  const handleChange = (selectedOptions) => {
    setSelectedHospitals(selectedOptions);
  };
  useEffect(() => {
    getAllHospitals();
  }, []);

  const hospitalOptions = hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  console.log("selectedHospitals", selectedHospitals);

  const handleSubmit = () => {
    setLoading(true);

    if (!selectedHospitals) {
      toast.warning("Please Select Hospital.");
      setLoading(false);
      return;
    }
    if (!cost) {
      toast.warning("Please enter the cost.");
      setLoading(false);
      return;
    }
    if (!type) {
      toast.warning("Please Select Treatment Type.");
      setLoading(false);
      return;
    }

    axiosInstance
      .post("/converted_patients", {
        hospital_id: selectedHospitals?.value,
        user_id: userId,
        patient_id: patinetId,
        cost: cost,
        leadType: type,
      })
      .then((response) => {
        toast.success("Emails sent successfully.");
        setSelectedHospitals("");
        setCost("");
        setType("");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Failed to send emails.");
        setLoading(false);
      });
  };

  return (
    <>
      <section id="home-mod">
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="scrollbar-1">
              <div className="query-form-page">
                <h2 style={{ color: "" }}>Add Converted Lead</h2>
                <div className="visa-form">
                  <div className="visa-form-box">
                    <label>Select Hospital*</label>
                    <Select
                      name="hospitals"
                      options={hospitalOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedHospitals}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Total Cost*</label>
                    <input
                      type="number"
                      value={cost}
                      onChange={(e) => setCost(e.target.value)}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Treatment Type*</label>
                    <select
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="">Select Type</option>
                      <option value="IPD">IPD</option>
                      <option value="OPD">OPD</option>
                      <option value="OTHER">OTHER</option>
                    </select>
                  </div>
                  <div className="visa-form-box">
                    <button
                      type="submit"
                      className="visa-submit"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Submit Now"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadConveredLead;
