import React, { useEffect, useState } from "react";
import LeftSlider from "../../Inc/LeftSlider";
import "./postarrival.css";
import { useParams } from "react-router-dom";
import { decryptId } from "../../utils/cryptoUtils";
import Select from "react-select";
import { useWebsiteApi } from "../../context/websiteContext";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { toast } from "react-toastify";

const PostArrivalForm = () => {
  const { pid, uid } = useParams();
  const { getAllHospitals, hospitals } = useWebsiteApi();
  const [loading, setLoading] = useState(false);
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const [selectedHospitals, setSelectedHospitals] = useState("");
  const [formData, setFormData] = useState({
    pickup: "",
    pickup_date: "",
    country: "",
    city: "",
    hotel_booking: "",
    hotel_name: "",
    hotel_address: "",
    description: "",
    status: "",
  });

  useEffect(() => {
    getAllHospitals();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlehospitalChange = (selectedOptions) => {
    setSelectedHospitals(selectedOptions);
  };

  const hospitalOptions = hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedHospitals) {
      toast.warning("Please Select Hospital");
      return;
    }
    setLoading(true);
    try {
      const data = {
        patient_id: patinetId,
        user_id: userId,
        hospital_id: selectedHospitals?.value,
        pickup: formData?.pickup,
        pickup_date: formData?.pickup_date,
        country: formData?.country,
        city: formData?.city,
        hotel_booking: formData?.hotel_booking,
        hotel_name: formData?.hotel_name,
        hotel_address: formData?.hotel_address,
        description: formData?.description,
      };
      const response = await axiosInstance.post("/post_arrival", data);

      if (response.status === 200) {
        toast.success("Form submitted successfully!");
        setFormData({
          pickup: "",
          pickup_date: "",
          country: "",
          city: "",
          hotel_booking: "",
          hotel_name: "",
          hotel_address: "",
          description: "",
          status: "",
        });
        setSelectedHospitals("");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section id="home-mod">
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="scrollbar-1">
              <div className="post-arrival-form">
                <h2>Post Arrival Form</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="pickup">Select Hospital</label>
                    <Select
                      name="hospitals"
                      options={hospitalOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedHospitals}
                      onChange={handlehospitalChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="pickup">Pickup</label>
                    <select
                      id="pickup"
                      name="pickup"
                      value={formData.pickup}
                      onChange={handleChange}
                    >
                      <option value="">Select option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="pickup_date">Pickup Date</label>
                    <input
                      type="date"
                      id="pickup_date"
                      name="pickup_date"
                      value={formData.pickup_date}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      id="country"
                      name="country"
                      placeholder="Enter country"
                      value={formData.country}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      placeholder="Enter city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="hotel_booking">Hotel Booking</label>
                    <select
                      id="hotel_booking"
                      name="hotel_booking"
                      value={formData.hotel_booking}
                      onChange={handleChange}
                    >
                      <option value="">Select option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="hotel_name">Hotel Name</label>
                    <input
                      type="text"
                      id="hotel_name"
                      name="hotel_name"
                      placeholder="Enter hotel name"
                      value={formData.hotel_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="hotel_address">Hotel Address</label>
                    <textarea
                      id="hotel_address"
                      name="hotel_address"
                      placeholder="Enter hotel address"
                      value={formData.hotel_address}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      id="description"
                      name="description"
                      placeholder="Enter description"
                      value={formData.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  {/* <div className="form-group">


                    <label htmlFor="status">Status</label>
                    <select id="status" name="status">
                      <option value="">Select status</option>
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>
                      <option value="canceled">Canceled</option>
                    </select>
                  </div> */}

                  <button
                    type="submit"
                    className="submit-button"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PostArrivalForm;
