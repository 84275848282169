import React, { useState } from "react";
import "./addpatient.css";
import LeftSlider from "../../Inc/LeftSlider";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import Header from "../../Inc/header/Header";
import { toast } from "react-toastify";

const AddPatient = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    gender: "",
    age: "",
    country: "",
    address: "",
    bio: "",
    subject: "CRM Manual - Add Patient ",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.phone) errors.phone = "Phone number is required";
    if (!formData.email) errors.email = "Email is required";
    if (formData.address.length > 40) {
      errors.address = "Address cannot exceed 40 characters";
    }
    if (formData.bio.length > 80) {
      errors.bio = "Bio cannot exceed 80 characters";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const response = await axiosInstance.post("/upload_patient", formData);
        toast.success("Lead Added successfully!");
        setFormData({
          name: "",
          phone: "",
          email: "",
          city: "",
          gender: "",
          age: "",
          country: "",
          address: "",
          bio: "",
          subject: "",
        });
        setLoading(false);
      } catch (error) {
        console.error("There was an error submitting the form!", error);
        toast.error("There was an error submitting the form!")
        setLoading(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      <section id="home-mod">
        <Header />
        <div className="home-mod">
          <LeftSlider path="add-patient" />
          <div className="home-mod-right">
            <div className="scrollbar-1">
              <div className="query-form-page">
                <h1>Add New Patient</h1>
                <div className="treatment-right">
                  <div className="treatment-form">
                    <div className="inputbox">
                      <label>Name*</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      <div
                        style={{
                          color: "red",
                          fontSize: "14px",
                          paddingBottom: "10px",
                        }}
                      >
                        {errors?.name}
                      </div>
                    </div>
                  </div>
                  <div className="treatment-form">
                    <div className="inputbox">
                      <label>Phone Number (With Country Code)*</label>
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      <div
                        style={{
                          color: "red",
                          fontSize: "14px",
                          paddingBottom: "10px",
                        }}
                      >
                        {errors?.phone}
                      </div>
                    </div>
                  </div>
                  <div className="treatment-form">
                    <div className="inputbox">
                      <label>Email*</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <div
                        style={{
                          color: "red",
                          fontSize: "14px",
                          paddingBottom: "10px",
                        }}
                      >
                        {errors?.email}
                      </div>
                    </div>
                  </div>
                  <div className="treatment-form">
                    <div className="inputbox">
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="treatment-form">
                    <div className="inputbox">
                      <label>Gender</label>
                      <select name="gender"
                        value={formData.gender}
                        onChange={handleChange}>
                        <option value="">Select Option</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="treatment-form">
                    <div className="inputbox">
                      <label>Age</label>
                      <input
                        type="text"
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="treatment-form">
                    <div className="inputbox">
                      <label>Country</label>
                      <input
                        type="text"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="treatment-form">
                    <div className="inputbox">
                      <label>Address</label>
                      <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      paddingBottom: "10px",
                    }}
                  >
                    {errors?.address}
                  </div>
                  <div className="treatment-form">
                    <div className="inputbox">
                      <label>bio</label>
                      <input
                        type="text"
                        name="bio"
                        value={formData.bio}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      paddingBottom: "10px",
                    }}
                  >
                    {errors?.bio}
                  </div>
                  {/* <div className="treatment-form">
                    <div className="inputbox">
                      <label>Subject</label>
                      <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </div>
                  </div> */}
                  <button
                    type="submit"
                    name="en"
                    className="home-button"
                    onClick={handleSubmit}
                  >
                    {loading ? "Loading..." : "Add Patient"}

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddPatient;
