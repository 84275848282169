import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./HomePage/App";
import reportWebVitals from "./reportWebVitals";
import "./assests/css/nav.css";
import "./assests/css/slimselect.css";
import "./assests/css/topnav.css";
import "./assests/css/pop.css";
import "./assests/css/main.css";
import "./assests/css/App.css";
import { GetLeadProvider } from "./context/getAllLeadContext";
import { WhatsappProvider } from "./context/whatsappContex";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import { GetPostArrivalProvider } from "./context/getPostArrivalContext";
import { WebsiteProvider } from "./context/websiteContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <GetLeadProvider>
          <GetPostArrivalProvider>
            <WhatsappProvider>
              <WebsiteProvider>
                <ToastContainer />
                <App />
              </WebsiteProvider>
            </WhatsappProvider>
          </GetPostArrivalProvider>
        </GetLeadProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
