import React, { useState, useEffect } from "react";
import LeftSlider from "../../Inc/LeftSlider";
import { useWebsiteApi } from "../../context/websiteContext";
import Select from "react-select";
import { useParams } from "react-router-dom";
import "../upload-passport/uploadPassport.css";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { decryptId } from "../../utils/cryptoUtils";
import TimePicker from "react-time-picker";
import { toast } from "react-toastify";

const TicketSend = () => {
  const { userIdE, patientIdE, specE } = useParams();
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const { getAllHospitals, hospitals } = useWebsiteApi();
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("");

  const userId = decryptId(userIdE);
  const patientId = decryptId(patientIdE);
  const spec = decryptId(specE);

  const [formData, setFormData] = useState({
    airport_pickup: "",
    visa_upload: "",
    ticket_upload: "",
    passport_number: "",
    appointment_time: "",
    arrival_date: "",
    airline_name: "",
    attendant_name1: "",
    att_passport_number1: "",
    attendant_name2: "",
    appointment_timeL: "",
    att_passport_number2: "",
    attendant_name3: "",
    att_passport_number3: "",
    attendant_name4: "",
    att_passport_number4: "",
    embassy: "",
    patient_passport: "",
    attendant_passport1: "",
    attendant_passport2: "",
    attendant_passport3: "",
    attendant_passport4: "",
    country: "",
    city: "",
    appointment_date: null,
    patient_id: "",
    user_id: "",
    review_reported_by: "",
    hospitals: [],
    speciality_id: "",
    doctor_name: "",
    visa1: "",
    visa2: "",
    visa3: "",
    visa4: "",
  });

  useEffect(() => {
    getAllHospitals();
  }, []);

  const handleArrivalDateChange = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      arrival_date: date,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      appointment_date: date,
    }));
  };

  const handleChange = (selectedOptions) => {
    setSelectedHospitals(selectedOptions);
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files[0]) {
      const file = files[0];
      const fileType = file.type;

      if (fileType !== "application/pdf") {
        toast.warning("Only PDF files are allowed.");
        return;
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleTimeChange = (selectedTime) => {
    const formattedTime = selectedTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    setTime(selectedTime);
    handleInputChange({
      target: { name: "appointment_time", value: formattedTime },
    });
  };

  const hospitalOptions = hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.passport_number || formData.passport_number.trim() === "") {
      toast.warning("Please enter the passport number.");
      setLoading(false);
      return;
    }
    if (!formData.patient_passport) {
      toast.warning("Please upload the patient passport.");
      setLoading(false);
      return;
    }
    if (!formData.visa_upload) {
      toast.warning("Please upload the patient passport.");
      setLoading(false);
      return;
    }
    if (!formData.ticket_upload) {
      toast.warning("Please upload the patient Ticket.");
      setLoading(false);
      return;
    }
    if (!formData.country || formData.country.trim() === "") {
      toast.warning("Please select a country.");
      setLoading(false);
      return;
    }
    if (!formData.appointment_date) {
      toast.warning("Please select an appointment date.");
      setLoading(false);
      return;
    }
    if (!formData.appointment_time) {
      toast.warning("Please Write an arrival time.");
      setLoading(false);
      return;
    }
    if (!formData.arrival_date) {
      toast.warning("Please Write an arrival time.");
      setLoading(false);
      return;
    }

    if (!formData.airline_name) {
      toast.warning("Please Write an Airline Name.");
      setLoading(false);
      return;
    }

    if (selectedHospitals.length > 0) {
      const hospitalDetails = selectedHospitals.map((selectedHospital) => {
        const fullDetails = hospitals.find(
          (hospital) => hospital.id === selectedHospital.value
        );
        return {
          id: fullDetails.id,
          name: fullDetails.name,
          hospital_email: fullDetails.hospital_email,
          hospital_phone: fullDetails.hospital_phone,
        };
      });

      const formDataToSend = new FormData();

      // Append form fields to FormData
      for (const key in formData) {
        if (formData[key]) {
          formDataToSend.append(key, formData[key]);
        } else {
          formDataToSend.append(key, null);
        }
      }

      // Convert hospitalDetails to JSON and append
      formDataToSend.append("hospitals", JSON.stringify(hospitalDetails));
      formDataToSend.append("patient_id", patientId);
      formDataToSend.append("user_id", userId);
      formDataToSend.append("speciality_id", spec);

      try {
        const response = await axiosInstance.post(
          "/ticketvisatohospital",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Ticket Send to Hospital Successfully");

        setFormData({
          visa_upload: "",
          ticket_upload: "",
          passport_number: "",
          appointment_time: "",
          arrival_date: "",
          airline_name: "",
          attendant_name1: "",
          att_passport_number1: "",
          attendant_name2: "",
          appointment_timeL: "",
          att_passport_number2: "",
          attendant_name3: "",
          att_passport_number3: "",
          attendant_name4: "",
          att_passport_number4: "",
          embassy: "",
          patient_passport: "",
          attendant_passport1: "",
          attendant_passport2: "",
          attendant_passport3: "",
          attendant_passport4: "",
          country: "",
          city: "",
          appointment_date: null,
          patient_id: "",
          user_id: "",
          review_reported_by: "",
          hospitals: [],
          speciality_id: "",
        });
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.warning("No hospitals selected.");
      setLoading(false);
    }
  };
  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <section id="home-mod">
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="scrollbar-1">
              <div className="query-form-page">

                <h2 style={{ color: "#ef5da2" }}>
                  Ticket Submission to Hospital
                </h2>
                <div className="visa-form">
                  <div className="visa-form-box">
                    <label>Select Hospital</label>
                    <Select
                      isMulti
                      name="hospitals"
                      options={hospitalOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedHospitals}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Doctor Name</label>
                    <input
                      type="text"
                      name="doctor_name"
                      value={formData.doctor_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Passport Number</label>
                    <input
                      type="text"
                      name="passport_number"
                      value={formData.passport_number}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Patient Passport</label>
                    <input
                      type="file"
                      name="patient_passport"
                      onChange={handleInputChange}
                    />
                    {formData.patient_passport &&
                      formData.patient_passport.type === "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(formData.patient_passport)}
                        style={{
                          width: "100%",
                          height: "500px", // Adjust height as needed
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Patient Visa</label>
                    <input
                      type="file"
                      name="visa_upload"
                      onChange={handleInputChange}
                    />
                    {formData.visa_upload &&
                      formData.visa_upload.type === "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(formData.visa_upload)}
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Upload Ticket</label>
                    <input
                      type="file"
                      name="ticket_upload"
                      onChange={handleInputChange}
                    />
                    {formData.ticket_upload &&
                      formData.ticket_upload.type === "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(formData.ticket_upload)}
                        style={{
                          width: "100%",
                          height: "500px", // Adjust height as needed
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Name 1</label>
                    <input
                      type="text"
                      name="attendant_name1"
                      value={formData.attendant_name1}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Passport Number 1</label>
                    <input
                      type="text"
                      name="att_passport_number1"
                      value={formData.att_passport_number1}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Passport 1</label>
                    <input
                      type="file"
                      name="attendant_passport1"
                      onChange={handleInputChange}
                    />
                    {formData.attendant_passport1 &&
                      formData.attendant_passport1.type ===
                      "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(
                          formData.attendant_passport1
                        )}
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Visa 1</label>
                    <input
                      type="file"
                      name="visa1"
                      onChange={handleInputChange}
                    />
                    {formData.visa1 &&
                      formData.visa1.type === "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(formData.visa1)}
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Name 2</label>
                    <input
                      type="text"
                      name="attendant_name2"
                      value={formData.attendant_name2}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Passport Number 2</label>
                    <input
                      type="text"
                      name="att_passport_number2"
                      value={formData.att_passport_number2}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Passport 2</label>
                    <input
                      type="file"
                      name="attendant_passport2"
                      onChange={handleInputChange}
                    />
                    {formData.attendant_passport2 &&
                      formData.attendant_passport2.type ===
                      "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(
                          formData.attendant_passport2
                        )}
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Visa 2</label>
                    <input
                      type="file"
                      name="visa2"
                      onChange={handleInputChange}
                    />
                    {formData.visa2 &&
                      formData.visa2.type === "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(formData.visa2)}
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Name 3</label>
                    <input
                      type="text"
                      name="attendant_name3"
                      value={formData.attendant_name3}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Passport Number 3</label>
                    <input
                      type="text"
                      name="att_passport_number3"
                      value={formData.att_passport_number3}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Passport 3</label>
                    <input
                      type="file"
                      name="attendant_passport3"
                      onChange={handleInputChange}
                    />
                    {formData.attendant_passport3 &&
                      formData.attendant_passport3.type ===
                      "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(
                          formData.attendant_passport3
                        )}
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Visa 3</label>
                    <input
                      type="file"
                      name="visa3"
                      onChange={handleInputChange}
                    />
                    {formData.visa3 &&
                      formData.visa3.type === "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(formData.visa3)}
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Name 4</label>
                    <input
                      type="text"
                      name="attendant_name4"
                      value={formData.attendant_name4}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Passport Number 4</label>
                    <input
                      type="text"
                      name="att_passport_number4"
                      value={formData.att_passport_number4}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Passport 4</label>
                    <input
                      type="file"
                      name="attendant_passport4"
                      onChange={handleInputChange}
                    />
                    {formData.attendant_passport4 &&
                      formData.attendant_passport4.type ===
                      "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(
                          formData.attendant_passport4
                        )}
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Attendant Visa 1</label>
                    <input
                      type="file"
                      name="visa4"
                      onChange={handleInputChange}
                    />
                    {formData.visa4 &&
                      formData.visa4.type === "application/pdf" ? (
                      <iframe
                        src={URL.createObjectURL(formData.visa4)}
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "1px solid #ccc",
                        }}
                        title="Patient Passport PDF"
                      />
                    ) : (
                      <p>
                        No PDF file selected or selected file is not a PDF.
                      </p>
                    )}
                  </div>
                  <div className="visa-form-box">
                    <label>Mode of Transport</label>
                    <input
                      type="text"
                      name="airline_name"
                      value={formData.airline_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Airport Pickup</label>
                    <select
                      name="airport_pickup"
                      value={formData.airport_pickup}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Option</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div className="visa-form-box">
                    <label>Embassy</label>
                    <input
                      type="text"
                      name="embassy"
                      value={formData.embassy}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Country</label>
                    <input
                      type="text"
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Arrival Date</label>
                    <DatePicker
                      selected={formData.arrival_date}
                      onChange={handleArrivalDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select appointment date"
                      minDate={new Date()}
                      className="date-picker-input"
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Arrival Time</label>
                    <DatePicker
                      selected={time}
                      onChange={handleTimeChange}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="Select time"
                      className="time-picker-input"
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Appointment Date</label>
                    <DatePicker
                      selected={formData.appointment_date}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select appointment date"
                      minDate={new Date()}
                      className="date-picker-input"
                    />
                  </div>
                  <div className="visa-form-box" onClick={handleSubmit}>
                    <button type="submit" name="en" className="visa-submit">
                      {" "}
                      Submit Now
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TicketSend;
