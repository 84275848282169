import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";

import {
  requestNotificationPermission,
  sendNotification,
} from "./notificationUtils";
import "react-calendar/dist/Calendar.css";
import "./NotificationScheduler.css"; // Custom styles
import LeftSlider from "../Inc/LeftSlider";

const NotificationScheduler = () => {
  const [date, setDate] = useState(new Date());
  const [notificationText, setNotificationText] = useState("");
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    requestNotificationPermission(); // Request permission on mount

    // Load notifications from localStorage
    const storedNotifications =
      JSON.parse(localStorage.getItem("notifications")) || [];
    setNotifications(storedNotifications);
  }, []);

  const handleSchedule = () => {
    const newNotification = {
      text: notificationText,
      date: date.toISOString(),
    };

    const updatedNotifications = [...notifications, newNotification];
    setNotifications(updatedNotifications);
    localStorage.setItem("notifications", JSON.stringify(updatedNotifications));

    alert("Notification scheduled!");
  };

  const handleManualNotification = () => {
    sendNotification("Manual Notification", {
      body: notificationText || "This is a test notification!",
      icon: "icon.png",
    });
  };

  const handleDelete = (index) => {
    // Remove the notification at the given index
    const updatedNotifications = notifications.filter((_, i) => i !== index);
    setNotifications(updatedNotifications);
    // Update localStorage with the new list
    localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
  };

  return (
    <section id="home-mod">
      <div className="home-mod">
        <LeftSlider path="notification" />
        <div className="home-mod-right">
          <div className="scheduler-container">
            <h2>Schedule Notification</h2>

            <div className="calendar-container">
              <Calendar onChange={setDate} value={date} />
            </div>

            <div className="notification-input-container">
              <input
                type="text"
                placeholder="Notification text"
                value={notificationText}
                onChange={(e) => setNotificationText(e.target.value)}
              />
              <button onClick={handleSchedule}>Schedule Notification</button>
            </div>

            <h3>Test Push Notification</h3>
            <button
              onClick={handleManualNotification}
              className="manual-notification-button"
            >
              Push Manual Notification
            </button>

            <div className="notification-list">
              <h3>Scheduled Notifications</h3>
              {notifications.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>Notification Text</th>
                      <th>Scheduled Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.map((notification, index) => (
                      <tr key={index}>
                        <td>{notification.text}</td>
                        <td>{new Date(notification.date).toLocaleString()}</td>
                        <td>
                          <button
                            onClick={() => handleDelete(index)}
                            className="delete-btn"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No notifications scheduled</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotificationScheduler;
