import React, { useEffect, useState } from "react";
import { FaUser, FaClock } from "react-icons/fa";
import { handleLogout } from "../../utils/handleLogout";
import Select from "react-select";
import ZoomInOut from "./ZoomInOut";


const Header = ({ drakMode }) => {
  // const {drakMode} = useUser()
  const [currentTime, setCurrentTime] = useState("");
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [greeting, setGreeting] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("Asia/Kolkata");


  const timeZones = [
    // Asia
    { label: "India", value: "Asia/Kolkata" },
    { label: "China", value: "Asia/Shanghai" },
    { label: "Japan", value: "Asia/Tokyo" },
    { label: "United Arab Emirates", value: "Asia/Dubai" },
    { label: "Saudi Arabia", value: "Asia/Riyadh" },
    { label: "Indonesia", value: "Asia/Jakarta" },
    { label: "Pakistan", value: "Asia/Karachi" },
    { label: "Bangladesh", value: "Asia/Dhaka" },
    { label: "Vietnam", value: "Asia/Ho_Chi_Minh" },
    { label: "Thailand", value: "Asia/Bangkok" },
    { label: "South Korea", value: "Asia/Seoul" },
    { label: "Singapore", value: "Asia/Singapore" },
    { label: "Malaysia", value: "Asia/Kuala_Lumpur" },
    { label: "Philippines", value: "Asia/Manila" },
    { label: "Afghanistan", value: "Asia/Kabul" },
    { label: "Nepal", value: "Asia/Kathmandu" },
    { label: "Sri Lanka", value: "Asia/Colombo" },
    { label: "Myanmar", value: "Asia/Yangon" },
    { label: "Kazakhstan", value: "Asia/Almaty" },
    { label: "Turkey", value: "Asia/Istanbul" },

    // Africa
    { label: "South Africa", value: "Africa/Johannesburg" },
    { label: "Nigeria", value: "Africa/Lagos" },
    { label: "Egypt", value: "Africa/Cairo" },
    { label: "Kenya", value: "Africa/Nairobi" },
    { label: "Ethiopia", value: "Africa/Addis_Ababa" },
    { label: "Ghana", value: "Africa/Accra" },
    { label: "Uganda", value: "Africa/Kampala" },
    { label: "Tanzania", value: "Africa/Dar_es_Salaam" },
    { label: "Morocco", value: "Africa/Casablanca" },
    { label: "Algeria", value: "Africa/Algiers" },
    { label: "Zambia", value: "Africa/Lusaka" },
    { label: "Zimbabwe", value: "Africa/Harare" },
    { label: "Sudan", value: "Africa/Khartoum" },
    { label: "Tunisia", value: "Africa/Tunis" },
    { label: "Libya", value: "Africa/Tripoli" },
    { label: "Somalia", value: "Africa/Mogadishu" },
    { label: "Senegal", value: "Africa/Dakar" },
    { label: "Botswana", value: "Africa/Gaborone" },
    { label: "Malawi", value: "Africa/Blantyre" },
    { label: "Rwanda", value: "Africa/Kigali" },
    { label: "Namibia", value: "Africa/Windhoek" },
  ];
  useEffect(() => {
    const name = localStorage.getItem("userName");
    const role = localStorage.getItem("userRole");
    if (name) {
      setUserName(name);
    }
    if (role) {
      setUserRole(role);
    }

    const hours = new Date().getHours();
    if (hours < 12) {
      setGreeting("Good Morning");
    } else if (hours < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  }, []);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const options = { timeZone: selectedTimeZone, hour12: true };
      const time = now.toLocaleTimeString("en-US", options);
      setCurrentTime(time);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, [selectedTimeZone]);

  const handleTimeZoneChange = (e) => {
    setSelectedTimeZone(e.target.value);
  };
  return (
    <>
      <div className="head-top" style={{ background: drakMode ? "#000" : "" }}>
        <div className="head-top-left" style={{ background: drakMode ? "#000" : "" }}>
          <img src="https://bharatonecare.com/images/2024/01/logo-footer.png" />
        </div>
        <div className="head-top-right">

          <div className="user-icon-container">
            <FaUser className="user-icon" />
          </div>
          <h1 className="header-title" style={{ color: drakMode ? "#fff" : "" }}>
            {greeting}, <span className="user-name" >{userName}</span>!
          </h1>

          <div className="time-container">
            <div className="timezone-selector">
              {/* <label htmlFor="timezone-select">Select Time Zone: </label> */}
              <select
                id="timezone-select"
                value={selectedTimeZone}
                onChange={handleTimeZoneChange}
              >
                {timeZones.map((zone) => (
                  <option key={zone.value} value={zone.value}>
                    {zone.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="time-display">
              <FaClock className="clock-icon" />
              <span className="current-time">{currentTime}</span>
            </div>
          </div>
          <ZoomInOut />
        </div>
      </div>
    </>
  );
};

export default Header;
