import React, { useState } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import ReminderList from "./ReminderList";
import { toast } from "react-toastify";

const SetReminderForm = ({
  patientId,
  agent_id,
  reminderType,
  reminderList,
  getBackgroundColor,
  getReminderList,
}) => {
  const [isReminderEnabled, setIsReminderEnabled] = useState(false); // State for reminder checkbox
  const [reminderDate, setReminderDate] = useState(""); // State for reminder date
  const [reminderDescription, setReminderDescription] = useState(""); // State for reminder description
  const [errors, setErrors] = useState({});
  const [selectedTime, setSelectedTime] = useState("");
  const [loading, setLoading] = useState(false);
  const times = Array.from({ length: 10 }, (_, i) => {
    const hour24 = i + 10; // Start from 10:00 AM (10 in 24-hour format)
    const hour12 = hour24 % 12 === 0 ? 12 : hour24 % 12; // Convert to 12-hour format
    return `${String(hour12).padStart(2, "0")}:00`; // Format as "01:00", "02:00", etc.
  });

  // Handle checkbox change (toggle reminder enable/disable)
  const handleReminderToggle = (event) => {
    setIsReminderEnabled(event.target.checked);
    if (!event.target.checked) {
      setErrors({});
    }
  };

  const handleTimeChange = (e) => setSelectedTime(e.target.value);

  // Handle date input change
  const handleDateChange = (event) => {
    setReminderDate(event.target.value);
  };

  // Handle description input change
  const handleDescriptionChange = (event) => {
    setReminderDescription(event.target.value);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!reminderDate) {
      newErrors.reminderDate = "Please select a date for the reminder";
    }
    if (!selectedTime) {
      newErrors.selectedTime = "Please select a time for the reminder";
    }
    if (!reminderDescription) {
      newErrors.reminderDescription = "Description is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateFields()) {
      return;
    }

    const reminderData = {
      schedule_date: `${reminderDate} ${selectedTime}:02`,
      description: reminderDescription,
      patient_id: patientId,
      agent_id: agent_id,
      reminder_name: reminderType,
    };

    try {
      setLoading(true);
      const response = await axiosInstance.post("/schedulePost", reminderData);

      toast.success("Reminder set successfully");
      setReminderDate("");
      setReminderDescription("");

      // Call the callback function safely
      try {
        await getReminderList(patientId, agent_id);
      } catch (callbackError) {
        console.error("Error fetching reminder list:", callbackError);
      }
    } catch (error) {
      console.error("Error during reminder submission:", error);
      toast.error("Failed to set reminder");
    } finally {
      setLoading(false);
    }
  };

  const today = new Date();
  today.setDate(today.getDate() + 1); // Add 1 day to today's date
  const minDate = today.toISOString().split("T")[0];

  const specificReminderList = reminderList?.filter(
    (item) => item.reminder_name === reminderType
  );

  return (
    <div>
      {/* <label>Set Reminder</label> */}
      <div>
        <input
          type="checkbox"
          id="setReminder"
          name="setReminder"
          checked={isReminderEnabled}
          onChange={handleReminderToggle}
        />
        <label htmlFor="setReminder" style={{ fontWeight: "700" }}>
          Enable Reminder
        </label>
      </div>

      {/* Show reminder fields only if reminder is enabled */}
      {isReminderEnabled && (
        <>
          <div id="reminderFields">
            <div>
              <label htmlFor="reminderDate">Reminder Date</label>
              <input
                type="date"
                id="reminderDate"
                name="reminderDate"
                value={reminderDate}
                onChange={handleDateChange}
                min={minDate}
              />
              {errors.reminderDate && (
                <p className="error">{errors.reminderDate}</p>
              )}
            </div>
            <div>
              <label htmlFor="reminderTime">Reminder Time</label>
              <select
                id="reminderTime"
                name="reminderTime"
                value={selectedTime}
                onChange={handleTimeChange}
              >
                <option value="">Select time</option>
                {times.map((time, index) => (
                  <option key={index} value={time}>
                    {time}
                  </option>
                ))}
              </select>
              {errors.selectedTime && (
                <p className="error">{errors.selectedTime}</p>
              )}
            </div>

            <div>
              <label htmlFor="reminderDescription">Description</label>
              <textarea
                id="reminderDescription"
                name="reminderDescription"
                placeholder="Enter reminder description"
                value={reminderDescription}
                onChange={handleDescriptionChange}
              ></textarea>
              {errors.reminderDescription && (
                <p className="error">{errors.reminderDescription}</p>
              )}
            </div>
            <button type="submit" onClick={handleSubmit} disabled={loading}>
              {loading ? "Loading..." : "Submit Reminder"}
            </button>
          </div>
          <ReminderList
            reminderList={specificReminderList}
            getBackgroundColor={getBackgroundColor}
          />
        </>
      )}
    </div>
  );
};

export default SetReminderForm;
