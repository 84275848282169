import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { handleLogout } from "../utils/handleLogout";
import { FaUserPlus, FaHome } from "react-icons/fa";
import { CgUserList } from "react-icons/cg";
import { IoIosPersonAdd } from "react-icons/io";
import { TbUserUp } from "react-icons/tb";
import { useUser } from "../context/UserContext";

const LeftSlider = ({ path }) => {
  const { user, localLoading ,drakMode } = useUser();
  const userRole = user?.role

  return (
    <>
      <div className="home-mod-left" style={{ background: drakMode ? "#000" : "" }}>
        <div className="navbar-nav-left">
          <ul>
            <li className={path === "home" ? "active" : ""}>
              {userRole === "2" ? (
                <Link to="/admin-lead-list">
                  <img src="/images/2024/01/01/1.png" alt="Admin Lead List" />{" "}
                  Admin Lead List
                </Link>
              ) : (
                <Link to="/">
                  <FaHome className="leftbar-icon" /> Home
                </Link>
              )}
            </li>

            <li className={path === "update-profile" ? "active" : ""}>
              <Link to="/update-profile">
                <TbUserUp className="leftbar-icon" /> Update Profile
              </Link>
            </li>

            {userRole !== "2" && (
              <li className={path === "create-user" ? "active" : ""}>
                <Link to="/create-user">
                  <FaUserPlus className="leftbar-icon" />
                  Create User
                </Link>
              </li>
            )}
            {userRole !== "2" && (
              <li className={path === "user-list" ? "active" : ""}>
                <Link to="/user-list">
                  <CgUserList className="leftbar-icon" /> User List
                </Link>
              </li>
            )}
            <li className={path === "add-patient" ? "active" : ""}>
              <Link to="/add-patient">
                <IoIosPersonAdd className="leftbar-icon" /> Add Patient
              </Link>
            </li>
            {/* <li className={path === "notification" ? "active" : ""}>
              <Link to="/notification">
                <img src="/images/2024/01/01/3.png" /> Web Notification 
              </Link>
            </li> */}
          </ul>
        </div>

        <div className="bottom-nav-bottom">
          <div className="bottom-box-bottom">
            {/* <p>Help & Support</p> */}
            <ul>
              {/* <li>
                <a href="#">
                  <img src="/images/2024/01/01/4.png" /> Contact Us
                </a>
              </li> */}
              <li onClick={handleLogout}>
                <a href="#">
                  <img src="/images/2024/01/01/4.png" /> Logout
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSlider;
