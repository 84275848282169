import React, { useState, useEffect } from 'react'
import { MdOutlineZoomInMap, MdZoomOutMap } from "react-icons/md";

const ZoomInOut = () => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const handleZoomIn = () => {
        const rootElement = document.getElementById("root");
        if (rootElement.requestFullscreen) {
            rootElement.requestFullscreen();
        } else if (rootElement.webkitRequestFullscreen) {
            rootElement.webkitRequestFullscreen();
        } else if (rootElement.msRequestFullscreen) {
            rootElement.msRequestFullscreen();
        }
        setIsFullscreen(true);
    };

    const handleZoomOut = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        setIsFullscreen(false);
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
        document.addEventListener("msfullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
            document.removeEventListener("msfullscreenchange", handleFullscreenChange);
        };
    }, []);

    return (
        <>
            <div className="zoom-controls">
                <button onClick={isFullscreen ? handleZoomOut : handleZoomIn} style={{ background: "none" }}>
                    {isFullscreen ? (
                        <MdOutlineZoomInMap size={20} style={{ marginRight: "5px", color: "#ef5da2" }} fontSize={50} />
                    ) : (
                        <MdZoomOutMap size={20} style={{ marginRight: "5px", color: "#ef5da2" }} />
                    )}
                </button>
            </div>
        </>
    )
}

export default ZoomInOut