import React, { useState } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { toast } from "react-toastify";

const VisaDateUpdate = ({ userId, patientId, getVisaSubmisiionDate }) => {
  const [visaSubmissionDate, setVisaSubmissionDate] = useState("");
  const [visaReceiveDate, setVisaReceiveDate] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (!visaSubmissionDate) {
      toast.warning("Please select Visa Submission Date");
      setLoading(false);
      return;
    }
    if (!visaReceiveDate) {
      toast.warning("Please select Visa Receive Date");
      setLoading(false);
      return;
    }
    try {
      const response = await axiosInstance.post(
        "/visaDocumentsReceiveSubmission",
        {
          receive_date: visaReceiveDate,
          sumission_date: visaSubmissionDate,
          user_id: userId,
          patient_id: patientId,
        }
      );
      toast.success("Dates submitted successfully!");
      getVisaSubmisiionDate(patientId, userId);
      setVisaSubmissionDate("");
      setVisaReceiveDate("");
    } catch (error) {
      console.error("Error submitting form: ", error);
      toast.error("There was an error submitting the form.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className="form-group">
        <label htmlFor="visaSubmissionDate">Visa Submission Date:</label>
        <input
          type="date"
          id="visaSubmissionDate"
          name="visaSubmissionDate"
          className="form-control"
          value={visaSubmissionDate}
          onChange={(e) => setVisaSubmissionDate(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="visaReceiveDate">Visa Receive Date:</label>
        <input
          type="date"
          id="visaReceiveDate"
          name="visaReceiveDate"
          className="form-control"
          value={visaReceiveDate}
          onChange={(e) => setVisaReceiveDate(e.target.value)}
        />
      </div>
      <div className="button-group" onClick={handleSubmit}>
        <button type="button" className="btn btn-danger">
          Submit
        </button>
      </div>
    </>
  );
};

export default VisaDateUpdate;
