import React from "react";
import Header from "../../Inc/header/Header";
import Home from "./Home";
import { useUser } from "../../context/UserContext";

const HomePage = () => {
  const { drakMode } = useUser()
  return (
    <>
      <section id="home-mod" style={{ background: drakMode ? "#000" : "" }}>
        <Header drakMode={drakMode} />
        <Home drakMode={drakMode} />
      </section>
    </>
  );
};

export default HomePage;
