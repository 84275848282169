import React, { useEffect, useState } from "react";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { toast } from "react-toastify";

const EditPatient = ({ toggleMessage, showMessage, selectedPatientId }) => {
  const { getPatientDetail, patientDetail } = useGetLeadApi();
  const [formData, setFormData] = useState({
    name: "",
    bio: "",
    country: "",
    city: "",
    gender: "",
    age: "",
    relation: "",
    address: "",
    patient_id: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    if (patientDetail) {
      setFormData({
        name: patientDetail.name || "",
        country: patientDetail.country || "",
        bio: patientDetail.bio || "",
        city: patientDetail.city || "",
        gender: patientDetail.gender || "",
        age: patientDetail.age || "",
        relation: patientDetail.relation || "",
        address: patientDetail.address || "",
        email: patientDetail.email || "",
        phone: patientDetail.phone || "",
        patient_id: selectedPatientId,
      });
    }
  }, [patientDetail, selectedPatientId]);

  useEffect(() => {
    if (selectedPatientId) {
      getPatientDetail(selectedPatientId);
    }
  }, [selectedPatientId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/updatePatientInfo", formData);
      toast.success("Patient information updated successfully.");
      toggleMessage();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to update patient information.")
    }
  };
  return (
    <>
      <div
        className="popup"
        data-popup="popup-1"
        style={{ display: showMessage ? "block" : "none" }}
      >
        <div className="popup-inner2">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="popup-close"
                data-popup-close="popup-1"
                data-dismiss="modal"
                onClick={toggleMessage}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <h2>Edit Patient Details</h2>
            <div className="scrollbar">
              <div className="treatment-form">
                <div className="inputbox">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="treatment-form">
                <div className="inputbox">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="treatment-form">
                <div className="inputbox">
                  <label>Phone (With Country Code)</label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="treatment-form">
                <div className="inputbox">
                  <label>Bio</label>
                  <input
                    type="text"
                    name="bio"
                    value={formData.bio}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="treatment-form">
                <div className="inputbox">
                  <label>Country</label>
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="treatment-form">
                <div className="inputbox">
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="treatment-form">
                <div className="inputbox">
                  <label>Gender</label>
                  <input
                    type="text"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="treatment-form">
                <div className="inputbox">
                  <label>Age</label>
                  <input
                    type="text"
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="treatment-form">
                <div className="inputbox">
                  <label>Relation</label>
                  <input
                    type="text"
                    name="relation"
                    value={formData.relation}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="treatment-form">
                <div className="inputbox">
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <button
                type="submit"
                name="en"
                className="home-button"
                onClick={handleSubmit}
              //   disabled={loading}
              >
                {/* {loading ? "Loading..." : "Submit Now"} */}
                Submit Now

              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPatient;
